const constants = {
  colours: {
    tripplo_blue: "#0458F6",
    tripplo_dark_blue: "#003EB3",
    white: "#FFFFFF",
    grey: "#ECECF1",
    grey_light: "#9FA2B4",
    grey_lightest: "#DFE0EB",
  },
}
export default constants
