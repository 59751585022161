import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import constants from "../constants"
import Logo from "../images/tripplo_logo.svg"
import { PageHeader, Button } from "antd"
const NavHeader = styled(PageHeader)`
  background: ${constants.colours.tripplo_blue};
  height: 223px;
  .ant-page-header-heading {
    padding: 3rem;
  }
  .ant-page-header-heading-extra {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 320px) {
    width: 380px;
  }
`

const NavLink = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 104.6%;
  padding-top: 0.8rem;
  color: ${constants.colours.white};
`

const ContactButton = styled(Button)`
  background: #ffffff;
  border-radius: 4px;
  width: 116px;
  height: 40px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin-left: 2rem;

  color: ${constants.colours.tripplo_dark_blue};
`

const Header = ({ siteTitle }) => (
  <NavHeader
    title={<img src={Logo} alt="tripplo-logo" />}
    extra={[
      <NavLink to="/">Home</NavLink>,
      <ContactButton>
        <a
          href="https://tripplo.co/contact.html "
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact
        </a>
      </ContactButton>,
    ]}
  ></NavHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
