/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import Header from "./header"
import "antd/dist/antd.css"
import constants from "../constants"
import Footer from "./footer"
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const GlobalStyles = createGlobalStyle`
  import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

  html {
    font-family: 'Roboto' , sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

body{
  margin: 0;
  padding:0;
  background: ${constants.colours.grey};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}


  `
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <GlobalStyles />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
