import styled from "styled-components"
import constants from "../constants"
const HomeHeading = styled.h1`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;

  color: ${constants.colours.tripplo_blue};
`

export default HomeHeading
