import React from "react"
import styled from "styled-components"
import constants from "../constants"
import { Row, Col } from "antd"
const FooterStyles = styled.footer`
  margin-top: 2rem;
  a {
    color: ${constants.colours.tripplo_dark_blue};
    padding: 2.7rem;

    font-weight: 500;
    font-size: 14px;
    margin-top: 1rem;
  }
`

const links = [
  { url: "https://app.tripplo.co", text: "app.tripplo.co" },
  { url: "https://tripplo.co", text: "www.tripplo.co" },
  { url: "https://tripplo.co/contact.html", text: "Contact us" },
  { url: "https://tripplo.co/privacy-policy.html", text: "Privacy policy" },
  { url: "https://tripplo.co/terms-of-use.html", text: "Terms of use" },
]
const Footer = () => (
  <FooterStyles>
    <Row justify="center">
      {links.map(l => (
        <Col>
          <a href={l.url} target="_blank" rel="noopener noreferrer">
            {l.text}
          </a>
        </Col>
      ))}
    </Row>
  </FooterStyles>
)

export default Footer
